/* Nav */

.nav {
	padding: 5px 0;
	background-color: white;
	border-bottom: 1px solid var(--nav-border);
	color: black;
	letter-spacing: normal;
}

.nav-row {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	column-gap: 30px;
	row-gap: 20px;
	flex-wrap: wrap;
}

.logo {
	margin-right: auto;
	color: black;
	font-size: 24px;
	font-family: 'Poppins', sans-serif;
}

.logo strong {
	font-weight: 700;
}

.nav-list {
	display: flex;
	flex-wrap: wrap;
	row-gap: 10px;
	align-items: center;
	column-gap: 20px;
	font-size: 16px;
	font-weight: 500;
	font-family: 'Poppins', sans-serif;
}

.nav-list--open {
	display: flex;
	flex-wrap: wrap;
	row-gap: 10px;
	align-items: center;
	column-gap: 40px;
	font-size: 16px;
	font-weight: 500;
	font-family: 'Poppins', sans-serif;
}

.nav-list__link {
	color: black;
	transition: opacity 0.2s ease-in;
}

.nav-list__link:hover {
	opacity: 0.8;
}

.nav-list__link--active {
	position: relative;
}



.nav-list__link--active::before {
	content: '';

	position: absolute;
	left: 0;
	top: 100%;

	display: block;
	height: 2px;
	width: 100%;
	background-color: var(--accent);
}

nav .menu {
	display: none;
	position: absolute;
	top: 1.5rem;
	right: 1rem;
	flex-direction: column;
	justify-content: space-between;
	width: 2.25rem;
	height: 2rem;
}

nav .menu span {
	height: 0.4rem;
	width: 100%;
	background-color: black;
	border-radius: 0.2rem;
}

@media (max-width: 920px) {
	nav .menu{
		display: flex;
	}

	nav {
		flex-direction: column;
		align-items: start;
	}

	.nav-list {
		display: none;
	}

	.nav-list li {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin-bottom: 0.25rem;

	}

	nav ul li {
		width: 100%;
		text-align: center;

	}
}

.cart-icon {
  color: black;
  text-decoration: none;
  font-size: 2rem;
  position: relative;
  
  	height: 3rem;
	width: 3rem;
	
	text-align: center;
	display: inline-block;
  }


.active-icon {
	border-radius: 50%;
  	background-color: var(--purple);
}

.logo {
	max-height: 55px;
}

.cart-count {
	background-color: var(--purple);
	color: #fff;
	border-radius: 50%;
	padding: 0.1rem 0.3rem;
	font-size: 0.7rem;
	position: absolute;
	top: -0.5rem;
	right: -0.1rem;
  }


  

