.tracking-container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.tracking-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    min-width: 60%;
}

.tracking-input.input-error {
    border-color: red;
}

.error-message {
    color: red;
    margin: 10px auto;
}

.response-message {
    margin: 10px auto;
}

.tracking-submit {
    padding: 10px;
    border-radius: 5px;
    background-color: var(--purple);
    color: white;
}

.loading-spinner {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid var(--purple);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.tracking-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: baseline;
    gap: 10px;
    background-color: #f4f4f4;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    max-width: 500px;
    margin: 10px auto;
}

.tracking-date {
    border: 2px solid var(--purple);
    padding: 10px;
    border-radius: 4px;
    font-weight: bold;
    color: var(--purple);
    text-align: center;
    max-width: 500px;
    margin: 10px auto;
}

/* Timeline Styles */
.tracking-timeline {
    position: relative;
    margin: 40px auto;
    border-left: 2px solid var(--purple);
    max-width: 600px;
}

.timeline-item {
    position: relative;
    margin-bottom: 20px;
}

.timeline-dot {
    position: absolute;
    left: -11px; /* Aligns the dot directly on the vertical line */
    top: 5px;
    width: 20px;
    height: 20px;
    background-color: var(--purple);
    border-radius: 50%;
    border: 4px solid white; /* Optional: Adds a border to give the dot a clear, defined look */
}

.timeline-content {
    margin-left: 20px;
}

.timeline-date {
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
}

.timeline-info {
    background-color: #f4f4f4;
    padding: 10px;
    border-radius: 4px;
    
}

/* Responsive design */
@media (max-width: 600px) {
    .tracking-input {
        font-size: 14px;
    }

    .tracking-submit {
        font-size: 14px;
        padding: 8px 12px;
    }

    .timeline-info {
        font-size: 14px;
    }
}
