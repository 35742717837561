.feedback-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 95%;
    gap: 15px;
    
    margin: auto;
}

.input-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

.input-row input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: vertical;
}

.submit-button {
    padding: 10px 20px;
    background-color: var(--purple);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.submit-button:hover {
    background-color: #930000;
}

.error-message {
    color: red;
}
