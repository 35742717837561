
.section-order__header {
    margin-bottom: 40px;
    margin-top: 20px;
    text-align: center;
}

.info-block {
    display: flex;
    gap: 20px;
    justify-content: space-around;
    background-color: #dfdfdf;
    border-radius: 4px;
    padding: 20px;
    max-width: 900px;
    margin: 0 auto;
    margin-bottom: 20px;
}

.row {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 10px;
}

.row-title{
    font-weight: bold;
}