/* HowItWorksSection.css */
.how-it-works {
    padding: 4rem 2rem;
    text-align: center;
    background-color: #f9f9f9;
  }
  
  .how-it-works h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #333;
  }
  
  .steps {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
    padding-left: 40px; /* Space for the vertical line */
  }
  
  .steps::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 20px; /* Position of the vertical line */
    width: 4px;
    background-color: transparent;
    border-left: 2px dotted var(--purple); /* Dotted line style */
  }
  
  .step {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    position: relative;
  }
  
  .step-number {
    position: absolute;
    left: -35px; /* Position of the step numbers */
    background-color: var(--purple);
    color: white;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  .step-content {
    background-color: white;
    padding: 1.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    width: calc(100% - 100px); /* Adjusted width for content */
    text-align: left;
    margin-left: 60px; /* Adjusted margin for content */
  }
  
  .step h3 {
    font-size: 1.75rem;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .step p {
    font-size: 1rem;
    color: #666;
    line-height: 1.5;
  }
  
  .step-image {
    display: flex;
    justify-content: center;
    width: auto;
    max-height: 250px;
    max-width: 90%;
    border-radius: 8px;
    opacity: 0.8;
    /* Removed border style */
    padding: 20px;
    margin: auto;
  }
  
  @media (min-width: 768px) {
    .steps {
      padding-left: 80px; /* Increase space for larger screens */
    }
  
    .step-number {
      left: -80px; /* Adjust step numbers position for larger screens */
      width: 44px;
      height: 44px;
      font-size: 1.5rem;
    }
  
    .step-content {
      margin-left: 80px; /* Adjust content alignment for larger screens */
      width: calc(100% - 120px); /* Adjusted width for content */
    }

  }

  @media (max-width: 600px) {
    .how-it-works {
      padding: 4rem 0rem;
    }
    
    .step-number {
      display: absolute;
      left: 2px;
    }

    .steps {
      position: relative;
      max-width: 800px;
      margin: 0 0;
      padding-left: 0px; /* Space for the vertical line */
    }
    
 
    
  }
  