

.profile-section {
    margin: 0 auto;
    padding: 20px;
    max-width: 800px;
    width: 100%;
}

.profile-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.form-input:disabled {
    background-color: #f9f9f9;
}

.form-button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.form-button:hover {
    background-color: #0056b3;
}

.logout-button {
    padding: 10px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    width: 150px;   
    margin: 100px auto;
}

.logout-button:hover {
    background-color: #c82333;
}

.container {
    display: flex;
    justify-content: center;
    flex-direction: column;
}