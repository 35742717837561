

/* Container for the form */
.sign-section {
    margin: auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 95%;
    max-width: 400px;
    text-align: center;
}

.sign-section h1 {
    color: #333;
}

/* Form Styles */
.sign-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sign-form label {
    margin: 10px 0 5px;
    color: #333;
    width: 100%;
    text-align: left;
}

.sign-form input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
}

.sign-form input[type=checkbox] {
   
    width: 20px;
}

.sign-form input:focus {
    border-color: #007BFF;
    outline: none;
}

/* Button Styles */
.sign-form button {
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 10px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
}

.sign-form button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Error Messages */
.errmsg {
    color: red;
    background-color: #f8d7da;
    padding: 10px;
    border: 1px solid #f5c2c7;
    border-radius: 4px;
    margin-bottom: 10px;
}

.error-offscreen {
    display: none;
}

/* Validation Icons */
.valid {
    color: green;
    margin-left: 10px;
}

.invalid {
    color: red;
    margin-left: 10px;
}

.hide {
    display: none;
}

/* Instruction Styles */
.instructions {
    font-size: 12px;
    background-color: #e7f3fe;
    color: #31708f;
    padding: 10px;
    border: 1px solid #bce8f1;
    border-radius: 4px;
    margin-top: 5px;
    text-align: left;
    width: 100%;
}

.instructions svg {
    margin-right: 5px;
}

/* Link Styles */
a {
    color: #26292d;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.line {
    display: inline-block;
    margin-top: 5px;
}

.line a{
    color: #007BFF;
}

.persistCheck {
    display: flex;
    gap: 5px;
    margin-bottom: 10px;
}

