    .container-buy {
        max-width: 900px;
        width: 90%;
        margin: 0 auto;
        padding: 20px;
        text-align: center;
    }

    .item-block {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 20px;
        padding: 15px;
        border-radius: 8px;
        width: 100%;
        transition: background-color 0.3s ease, border 0.3s ease;
        position: relative;
    }

    .item-block.editing {
        border: 2px solid #ccc;
        background-color: #fff;
    }

    .item-block.saved {
        border: 2px solid #ddd;
        background-color: #f0f0f0;
    }

    .item-block label {
        text-align: left;
        margin-bottom: 5px;
        display: block;
    }

    .radio-container {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .input-container {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }

   

    .input-container input[type="text"],
    .input-container input[type="number"],
    .input-container input[type="file"],
    .input-container select {
        flex-grow: 1;
        padding: 10px;
        border: 2px solid #ccc;
        border-radius: 4px;
        font-size: 16px;
        transition: background-color 0.3s ease, border 0.3s ease;
    }

    .input-container input[type="text"].error,
    .input-container input[type="number"].error,
    .input-container input[type="file"].error {
        border-color: red;
    }

    .input-container input[type="text"]:disabled,
    .input-container input[type="number"]:disabled,
    .input-container input[type="file"]:disabled {
        background-color: #e9ecef;
        border-color: #ddd;
    }

    .input-container input[type="text"]:focus,
    .input-container input[type="number"]:focus,
    .input-container input[type="file"]:focus {
        outline: none;
        border-color: #80bdff;
    }

    .tooltip-container {
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        cursor: pointer;
        position: relative;
    }

    .tooltip-container .tooltip {
        visibility: hidden;
        width: 150px;
        background-color: #333;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 150%;
        transform: translateY(-50%);
        opacity: 0;
        transition: opacity 0.3s;
    }

    .tooltip-container .tooltip::after {
        content: "";
        position: absolute;
        top: 50%;
        left: -10px;
        transform: translateY(-50%);
        border-width: 5px;
        border-style: solid;
        border-color: transparent #333 transparent transparent;
    }

    .tooltip-container:hover .tooltip {
        visibility: visible;
        opacity: 1;
    }

    .tooltip-icon {
        width: 20px;
        height: 20px;
        background-color: #6c757d;
        color: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
    }

    .block-buttons {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-top: 10px;
        gap: 5px;
        justify-content: center;
    }

    .block-buttons button {
        width: 48%;
        padding: 10px;
        border: none;
        border-radius: 4px;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease, box-shadow 0.3s ease;
    }

    .block-buttons .save {
        background-color: #28a745;
        color: white;
    }

    .block-buttons .save:hover {
        background-color: #218838;
    }

    .block-buttons .edit {
        background-color: #ffc107;
        color: white;
    }

    .block-buttons .edit:hover {
        background-color: #e0a800;
    }

    .block-buttons .delete {
        background-color: #dc3545;
        color: white;
    }

    .block-buttons .delete:hover {
        background-color: #c82333;
    }

    .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        gap: 10px;
        position: relative; /* Added for tooltip positioning */
    }

    .buttons button {
        padding: 10px 20px;
        border: none;
        border-radius: 4px;
        background-color: #007bff;
        color: white;
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.3s ease, box-shadow 0.3s ease;
    }

    .buttons button:hover {
        background-color: #0056b3;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .buttons button:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }

    #add-to-cart-button {
        background-color: #17a2b8;
    }

    #add-to-cart-button:hover {
        background-color: #138496;
    }

    #add-to-cart-button:disabled {
        background-color: #aaa;
        color: #666;
    }

    .cart-tooltip-container {
        position: relative;
        display: inline-block;
    }

    .cart-tooltip-container .tooltip {
        visibility: hidden;
        width: 200px;
        background-color: #333;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        top: -5px;
        left: 105%;
        transform: translateY(-50%);
        opacity: 0;
        transition: opacity 0.3s;
    }

    .cart-tooltip-container .tooltip::after {
        content: "";
        position: absolute;
        top: 50%;
        left: -5px;
        transform: translateY(-50%);
        border-width: 5px;
        border-style: solid;
        border-color: transparent #333 transparent transparent;
    }

    .cart-tooltip-container:hover .tooltip {
        visibility: visible;
        opacity: 1;
    }

    .size-color-amount-fields {
        margin-bottom: 10px;
        
        
    }

    .size-color-amount-field {
        display: flex;
        align-items: center;
        gap: 10px;
        padding-bottom: 10px;
    }

    .size-color-amount-field-block {
        display: flex;
        gap: 5px;
        border: 2px solid #ccc;
        border-radius: 4px;
        padding: 10px;
        width: 100%;
        justify-content: space-evenly;
    }

    .size-color-amount-field-group {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    

    .size-color-amount-field input[type="text"],
    .size-color-amount-field input[type="number"] {
        flex-grow: 1;
        padding: 8px;
        border: 2px solid #ccc;
        border-radius: 4px;
        font-size: 16px;
        
    }

    .size-color-amount-field input[type="text"].error,
    .size-color-amount-field input[type="number"].error {
        border-color: red;
    }

    .size-color-amount-field input[type="text"]:disabled,
    .size-color-amount-field input[type="number"]:disabled {
        background-color: #e9ecef;
        border-color: #ddd;
    }

    .size-color-amount-field input[type="text"]:focus,
    .size-color-amount-field input[type="number"]:focus {
        outline: none;
        border-color: #80bdff;
    }

    .size-color-amount-field button.delete-size-color-amount {
        padding: 8px;
        background-color: #dc3545;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease, box-shadow 0.3s ease;
    }

    .size-color-amount-field button.delete-size-color-amount:hover {
        background-color: #c82333;
    }

    .size-color-amount-field button.delete-size-color-amount:disabled {
        background-color: #a3a3a3;
    }

    .size-color-amount-fields button.add-size-color-amount {
        padding: 8px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease, box-shadow 0.3s ease;
    }

    .size-color-amount-fields button.add-size-color-amount:hover {
        background-color: #0056b3;
    }

    .size-color-amount-fields button.add-size-color-amount:disabled {
        background-color: #a3a3a3;
    }

    @media (max-width: 1150px) {
        .tooltip-container .tooltip {
            left: -650%;
        }

        .tooltip-container .tooltip::after {
            left: 150px;
            transform: translateY(-50%);
            border-color: transparent  transparent  transparent #333;
        }


    }

    @media (max-width: 828px) {

        .size-color-amount-field-block {
            flex-direction: column;
        }


    }

