@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');

$mobile: 600px;
$mobile-xs: 400px;

/* Reset and base styles  */
* {
	padding: 0px;
	margin: 0px;
	border: none;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

:focus,
:active {
	/*outline: none;*/
}

a:focus,
a:active {
	/* outline: none;*/
}

/* Links */

a, a:link, a:visited  {
    /* color: inherit; */
    text-decoration: none;
    /* display: inline-block; */
}

a:hover  {
    /* color: inherit; */
    text-decoration: none;
}

/* Common */

aside, nav, footer, header, section, main {
	display: block;
}

h1, h2, h3, h4, h5, h6, p {
    font-size: inherit;
	font-weight: inherit;
}

ul, ul li {
	list-style: none;
}

img {
	vertical-align: top;
}

img, svg {
	//max-width: 100%;
	height: auto;
}

/* Form */

input, textarea, button, select {
	font-family: inherit;
    font-size: inherit;
    color: inherit;
}

input::-ms-clear {
	display: none;
}

button, input[type="submit"] {
    display: inline-block;
    box-shadow: none;
    background-color: transparent;
    background: none;
    cursor: pointer;
}

input:focus, input:active,
button:focus, button:active {
    outline: none;
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

label {
	cursor: pointer;
}

legend {
	display: block;
}


body {
    font-family: 'Montserrat', sans-serif;
}

.container {
    max-width: 1140px;
    padding: 0 15px;
    margin-left: auto;
    margin-right: auto;
}

.button-order {
    display: flex;
    margin: auto;
    margin-top: 20px;
    max-width: 160px;
    justify-content: center;
    padding: 10px;
    border: none;
    color: #fff;
    border-radius: 4px;
    background: #28a745;
}

.button-order:disabled {
    background: hsl(0, 0%, 61%);
}



.title-1 {
    font-size: 28px;
    line-height: 1.3;
    font-weight:rgb(70, 70, 70)
}

.section-cart {
    padding: 20px 0 90px;
}

.section-cart__header {
    margin-bottom: 40px;
    text-align: center;
}

.section-cart__body {

}

.cart-header {
    display: grid;
    grid-template-columns: 2fr repeat(3, 3fr) 1fr;
    column-gap: 30px;

    padding: 22px 0;
    background-color: #EAEFF2;

    font-weight: 700;
    font-size: 13px;
    line-height: 1.2;
    letter-spacing: 0.2em;
    text-transform: uppercase;

    @media (max-width: $mobile) {
        height: 1px;
        padding: 0;
        & > * {
            display: none;
        }
    }
}

.cart-header__title {
    grid-column-start: 2;
}

.cart-footer {
    display: grid;
    grid-template-columns: 2fr repeat(3, 3fr) 1fr;
    column-gap: 30px;

    padding: 19px 0;
    background: #EAEFF2;

    font-weight: 700;
    font-size: 18px;
    line-height: 1.2;

    @media (max-width: $mobile) {
        padding-left: 15px;
        padding-right: 15px;
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: $mobile-xs) {
        grid-template-columns: 1fr;
        row-gap: 10px;
    }
}

.cart-footer__count {
    grid-column-start: 3;

    @media (max-width: $mobile) {
        grid-column-start: 1;
    }
}

.cart-totals {
    margin: 0 auto;
    max-width: 800px;
}

.cart-total {
    text-align: center;
    font-size: large;
}

.cart-totalprice {
    text-align: center;
    font-weight: bold;
    font-size: large;
}

.cart-disclamer {
    margin-top: 10px;
    color: gray;
    text-align: center;
    font-size: large;
}

.count {
    display: flex;
    column-gap: 10px;
    align-items: center;
}

.count__box {
    width: 40px;
    height: 30px;
    background: #EAEFF2;
    border-radius: 3px;
}

.count__input {
	max-width: 100%;
    padding-top: 4px;
	background-color: transparent;

    text-align: center;
    font-weight: 600;
	font-size: 18px;
	line-height: 1.3;
}

.count__input::-webkit-inner-spin-button,
.count__input::-webkit-outer-spin-button {
    appearance: none;
}

.count__controls {
    display: flex;
    flex-direction: column;
    row-gap: 9px;
}

.count__controls img {
    display: block;
}


.product {
    display: grid;
    grid-template-columns: 2fr repeat(3, 3fr) 1fr;
    column-gap: 30px;

    padding: 20px 0;
    border-bottom: 1px solid rgba(126, 155, 189, 0.3);

    font-weight: 600;
    font-size: 18px;
    line-height: 1.3;

    @media (max-width: $mobile) {
        grid-template-columns: repeat(6, 1fr);
        grid-auto-rows: minmax(60px, auto);
        column-gap: 15px;
        row-gap: 10px;
    }
}

.product > * {
    align-self: center;
}

.product__img {
    justify-self: center;
    @media (max-width: $mobile){
        grid-column: 1/3;
    }
}

.product__title {
    @media (max-width: $mobile){
        grid-column: 3/-1;
    }
}
.product__count {
    @media (max-width: $mobile) {
		grid-column: 1/3;
		justify-self: center;
	}
}

.product__price {
    @media (max-width: $mobile) {
		grid-column: 3/6;
	}
}

.product__controls {
    justify-self: center;
}