/* Header */

.header {
	padding: 40px 0;
	min-height: 595px;

	display: flex;
	justify-content: center;
	align-items: center;

	background-color: var(--header-bg);
	background-image:  url('./../../img/slider/slider_test_11.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;

	color: var(--header-text);
	text-align: center;
}

.header-buy {
	padding: 40px 0;
	min-height: 70px;

	display: flex;
	justify-content: center;
	align-items: center;

	background-color: var(--header-bg);
	background-image: url('./../../img/buy.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;

	color: var(--header-text);
	text-align: center;
}

.header__wrapper {
	padding: 0 15px;
	max-width: 660px;
	text-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.header__title {
	margin-bottom: 20px;
	font-size: 40px;
	font-weight: 700;
	line-height: 1.4;
}

.header__buttons {
	display: flex;
	justify-content: center;
	gap: 20px;
}

.header__desc {
	margin-bottom: 20px;
	font-size: 30px;
	font-weight: 600;
	line-height: 1.4;
}

.header__title strong {
	font-size: 60px;
	font-weight: 700;
}

.header__title em {
	font-style: normal;
	color: var(--accent);
}

.header__text {
	margin-bottom: 40px;
	font-size: 18px;
	line-height: 1.333;
}

.header__text p + p {
	margin-top: 0.5em;
}

@media (max-width: 1050px) {
	

	.header {
		background-image:  url('./../../img/slider/mobile1.jpg');
	}

}