.wrapper {
    border: 1px solid gray;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  .wrapper .question-container {
    width: 100%;
    text-align: left;
    padding: 20px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 20px;
    background: transparent;
    border: none;
    cursor: pointer;
  }
  
  .question-container.active {
    color: white;
    background-image: linear-gradient(var(--purple),rgb(144, 25, 25));
  }
  
  .wrapper .question-container:hover {
    color: white;
    background-image: linear-gradient(var(--purple),rgb(214, 114, 114));
  }
  
  .wrapper .arrow {
    font-size: 2rem;
    transition: .5s ease-in-out;
  }
  
  .arrow.active {
    rotate: 180deg;
    color: white;
  }
  
  .wrapper .answer-container {
    padding: 0 1rem;
    background-image: linear-gradient(90deg,transparent,rgba(0, 0, 0, 0.04),transparent);
    transition: height .3s ease-in-out;
  }
  
  .wrapper .answer-content {
    padding: 1rem 0;
    font-size: 20px;
    white-space: pre-wrap;
  }

  .answer-content a {
    color: var(--purple); /* Выберите нужный цвет для текста ссылки */
    text-decoration: underline; /* Подчёркивание */
    transition: color 0.3s ease; /* Анимация при наведении */
}

.answer-content a:hover {
    color: #660000; /* Цвет ссылки при наведении */
    text-decoration: none; /* Убираем подчеркивание при наведении */
}